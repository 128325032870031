<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="94px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item prop="name" label="名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="description" label="描述">
                        <el-input v-model="formData.description"></el-input>
                    </el-form-item>
                    <el-form-item prop="type" label="类型">
                        <el-select v-model="formData.type" clearable filterable placeholder="请选择">
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="icon" label="图标">
                        <multi-upload v-model="formData.icon"></multi-upload>
                    </el-form-item>
                    <el-form-item prop="once" label="一次性">
                        <el-switch v-model="formData.once"></el-switch>
                    </el-form-item>
                    <el-form-item prop="refreshOnTransfer" label="交易后刷新">
                        <el-switch v-model="formData.refreshOnTransfer"></el-switch>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PrivilegeOptionEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('privilegeOption/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: { once: false, refreshOnTransfer: false },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                description: [
                    {
                        required: true,
                        message: '请输入描述',
                        trigger: 'blur'
                    }
                ],
                intro: [
                    {
                        required: true,
                        message: '请输入说明',
                        trigger: 'blur'
                    }
                ],
                detail: [
                    {
                        required: true,
                        message: '请输入详情',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入类型',
                        trigger: 'blur'
                    }
                ],
                icon: [
                    {
                        required: true,
                        message: '请输入图标',
                        trigger: 'blur'
                    }
                ],
                once: [
                    {
                        required: true,
                        message: '请输入一次性',
                        trigger: 'blur'
                    }
                ],
                refreshOnTransfer: [
                    {
                        required: true,
                        message: '请输入交易后刷新',
                        trigger: 'blur'
                    }
                ]
            },
            typeOptions: [
                { label: '文本', value: 'text' },
                { label: '二维码', value: 'qrcode' },
                { label: '验证码', value: 'code' },
                { label: '铸造', value: 'exchange' }
            ]
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/privilegeOption/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/privilegeOption/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
